import React from 'react';

import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import ClearButton from './Shared/ClearButton';

import LocationFilter from './LocationFilter';

const AgendaFilter = (props) => {
  const renderFromDate = () => (
    <div className="d-flex align-items-center gap-2">
      <Form.Control type="date" onChange={(e) => props.setStartDate(e.target.value)} value={props.startDate} />
    </div>
  );

  const renderTillDate = () => (
    <div className="d-flex align-items-center gap-2">
      <Form.Control type="date" onChange={(e) => props.setEndDate(e.target.value)} value={props.endDate} />
    </div>
  );

  const renderProvince = () => (
    <Form.Control type="text" />

    /*
      <>
      <Form.Check label="Groningen" />
      <Form.Check label="Friesland" />
      <Form.Check label="Drenthe" />
      <Form.Check label="Overijssel" />
      <Form.Check label="Flevoland" />
      <Form.Check label="Gelderland" />
      <Form.Check label="Utrecht" />
      <Form.Check label="Noord-Holland" />
      <Form.Check label="Zuid-Holland" />
      <Form.Check label="Zeeland" />
      <Form.Check label="Noord-Brabant" />
      <Form.Check label="Limburg" />
    </>
    */
  );

  const renderSearch = () => (
    <div className="position-relative">
      <Form.Control
        onChange={(e) => props.setQuery(e.target.value)}
        placeholder="Vul zoekwoord in"
        type="text"
        value={props.query}
      />
      {props.query.length > 0 && <ClearButton onClick={() => props.setQuery('')} />}
    </div>
  );

  const renderSwitch = (param) => {
    switch (props.type) {
      case 'fromDate':
        return renderFromDate();
      case 'tillDate':
        return renderTillDate();
      case 'location':
        return (
          <LocationFilter
            city={props.city}
            distance={props.distance}
            setCity={props.setCity}
            setDistance={props.setDistance}
          />
        );
      case 'province':
        return renderProvince();
      case 'search':
        return renderSearch();
      default:
        return <></>;
    }
  };

  return (
    <Form.Group className="filter-group">
      <Form.Label>{props.title}</Form.Label>
      {renderSwitch(props.type)}
    </Form.Group>
  );
};

AgendaFilter.defaultProps = {
  opened: true,
};

export default AgendaFilter;
